import axios from "axios";
import { ApiAxiosRequestConfig } from "./csrf";

export interface APIAllocationResult {
    created: string;
    id: number;
    participant_ref: string;
    allocation: {
        arm: string;
        arm_id: string;
    };
}

export interface AllocationOutcome {
    success: boolean;
    result: APIAllocationResult | undefined;
    error_message: string | undefined;
}

export const generateAllocation = async (
    trialId: string,
    participantId: string,
    inputs: object = {}
): Promise<AllocationOutcome> => {
    try {
        const alloc_endpoint = "/api/v1/clinical-trials/" + trialId + "/randomise";
        return await axios
            .post<APIAllocationResult>(
                alloc_endpoint,
                {
                    participant_ref: participantId,
                    inputs: inputs,
                },
                ApiAxiosRequestConfig()
            )
            .then((res) => {
                return {
                    success: true,
                    result: res.data,
                    error_message: undefined,
                };
            })
            .catch((err) => {
                let errorMessage = "";
                if (err.response.data.error) {
                    errorMessage = err.response.data.error;
                } else if (err.response) {
                    const httpStatusText = err.response.statusText;
                    errorMessage = `${httpStatusText}: please check your entered values.`;
                } else if (err.request) {
                    errorMessage = "No response received from randomisation model. Please try again later.";
                } else {
                    errorMessage = "Network error. Please try again later.";
                }
                return {
                    success: false,
                    result: undefined,
                    error_message: errorMessage,
                };
            });
    } catch (err) {
        return {
            success: false,
            result: undefined,
            error_message: "Internal server error.",
        };
    }
};
