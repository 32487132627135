import React from "react";
import axios from "axios";
import { ApiAxiosRequestConfig } from "./csrf";

interface User {
    pk: number;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
}

interface AuthState {
    // is the state valid.. e.g meaningful. if false, we are talking to the auth API
    valid: boolean;
    // was there an error obtaining the state?
    error: string | undefined;
    // was there an error logging in?
    login_error: string | undefined;
    // if the user is undefined, we are not logged in
    user: User | undefined;
}

const initialAuthState = {
    valid: false,
    error: undefined,
    login_error: undefined,
    user: undefined,
};

type AuthAction =
    | { type: "auth_start" }
    | { type: "auth_logout" }
    | { type: "auth_login_error"; error: string }
    | { type: "auth_error"; error: string }
    | { type: "auth_login"; user: User | undefined };

const auth_reducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case "auth_start":
            return {
                ...state,
                valid: false,
            };
        case "auth_error":
            return {
                ...state,
                valid: false,
                error: action.error,
            };
        case "auth_login_error":
            return {
                ...state,
                valid: true,
                user: undefined,
                login_error: action.error,
            };
        case "auth_login":
            return {
                ...state,
                user: action.user,
                login_error: undefined,
                valid: true,
            };
        case "auth_logout":
            return {
                ...state,
                user: undefined,
                valid: true,
            };
    }
};

export interface IAuthContext {
    state: AuthState;
    dispatch: React.Dispatch<AuthAction>;
}

export const AuthContext = React.createContext<IAuthContext>({
    state: initialAuthState,
    dispatch: () => null,
});

export const AuthLogin = async (dispatch: React.Dispatch<AuthAction>, username: string, password: string) => {
    try {
        await axios.post<User>(
            "/api/v1/auth/login/",
            {
                username: username,
                password: password,
            },
            ApiAxiosRequestConfig()
        );
        await getUser(dispatch);
    } catch (exc) {
        dispatch({ type: "auth_login_error", error: "username or password incorrect" });
    }
};

export const AuthLogout = async (dispatch: React.Dispatch<AuthAction>) => {
    axios.post("/api/v1/auth/logout/", {}, ApiAxiosRequestConfig()).then((res) => {
        dispatch({ type: "auth_logout" });
    });
};

const csrfPing = async (dispatch: React.Dispatch<AuthAction>) => {
    try {
        await axios.get("/api/v1/ping", ApiAxiosRequestConfig());
        return true;
    } catch {
        dispatch({ type: "auth_error", error: "unable to communicate with API" });
        return false;
    }
};

const getUser = async (dispatch: React.Dispatch<AuthAction>) => {
    try {
        const resp = await axios.get<User>("/api/v1/auth/user/", ApiAxiosRequestConfig());
        dispatch({ type: "auth_login", user: resp.data });
    } catch (e: any) {
        // DRF returns either 403 (Forbidden) or 401 (Unauthorized) if we are not logged in
        // https://www.django-rest-framework.org/api-guide/authentication/
        if (e.response && (e.response.status === 403 || e.response.status === 401)) {
            // not an error, we simply aren't logged in
            dispatch({ type: "auth_login", user: undefined });
            return;
        }
        dispatch({ type: "auth_error", error: "unable to communicate with the authentication API" });
        return;
    }
};

export const AuthProvider: React.FC = ({ children }) => {
    const [state, dispatch] = React.useReducer(auth_reducer, initialAuthState);

    React.useEffect(
        () => {
            async function bootstrapAuth() {
                dispatch({ type: "auth_start" });
                if (!(await csrfPing(dispatch))) {
                    return;
                }
                await getUser(dispatch);
            }
            bootstrapAuth();
        },
        [] // only run this bootstrap check of authentication status once
    );

    return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};
