import { Container, Col, Row } from "reactstrap";

interface SimpleErrorProp {}

export default function SimpleError(props: React.PropsWithChildren<SimpleErrorProp>) {
    return (
        <Container>
            <Row>
                <Col>{props.children}</Col>
            </Row>
        </Container>
    );
}
