import axios from "axios";
import { ApiAxiosRequestConfig } from "./csrf";

export interface APISchemaResult {
    // created: string,
    // id: number,
    // participant_ref: string,
    result: object;
}

export interface SchemaOutcome {
    success: boolean;
    result: APISchemaResult | undefined;
    error_message: string | undefined;
}

// get input parameters from randomisation model, to build Randomisation form
export const getSchema = async (trialId: string): Promise<SchemaOutcome> => {
    try {
        const schema_endpoint = "/api/v1/clinical-trials/" + trialId + "/inputs/description";
        const result = await axios.get<APISchemaResult>(schema_endpoint, ApiAxiosRequestConfig());
        return {
            success: true,
            result: result.data,
            error_message: undefined,
        };
    } catch {
        return {
            success: false,
            result: undefined,
            error_message: "Problem with Random API",
        };
    }
};
