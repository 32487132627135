import React from "react";
import {
    Card,
    CardBody,
    Container,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Col,
    Row,
    UncontrolledAlert,
} from "reactstrap";
import { AuthLogin, AuthContext } from "../API/auth";
import { InputType } from "reactstrap/types/lib/Input";

function useInput(type: InputType): [string, JSX.Element] {
    const [value, setValue] = React.useState("");
    const inputId = type === "text" ? "username" : "password";
    const input = <Input id={inputId} value={value} type={type} onChange={(e) => setValue(e.target.value)} />;
    return [value, input];
}

export default function Login() {
    const { state, dispatch } = React.useContext(AuthContext);
    const [username, usernameInput] = useInput("text");
    const [password, passwordInput] = useInput("password");

    const failureMessage = () => {
        if (state.login_error) {
            return <UncontrolledAlert color="danger">Login failed: {state.login_error}</UncontrolledAlert>;
        }
    };

    const submitDisabled = () => username.length === 0 || password.length === 0;

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const doLogin = async () => {
            await AuthLogin(dispatch, username, password);
        };

        e.preventDefault();
        doLogin();
    };

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <h1>Login</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Access to this system is restricted. Please log in.</p>
                    </Col>
                </Row>
                {failureMessage()}
                <Card className="login">
                    <CardBody>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup row>
                                <Label for="username" sm={2}>
                                    Username
                                </Label>
                                <Col sm={12}>{usernameInput}</Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="password" sm={2}>
                                    Password
                                </Label>
                                <Col sm={12}>{passwordInput}</Col>
                            </FormGroup>
                            <Button type="submit" disabled={submitDisabled()}>
                                Log in
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}
