import { AxiosRequestConfig } from "axios";
import Cookies from "universal-cookie";

function getCSRFToken() {
    let c = new Cookies();
    return c.get("csrftoken");
}

export const ApiAxiosRequestConfig = (): AxiosRequestConfig => {
    return {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCSRFToken(),
        },
    };
};
