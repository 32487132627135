import React from "react";
import "./App.css";
import { faRandom, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthProvider, AuthContext, IAuthContext, AuthLogout } from "./API/auth";
import { HashRouter, Route } from "react-router-dom";
import LoginForm from "./Forms/Login";
import LandingPage from "./Forms/LandingPage";
import SimpleError from "./Forms/SimpleError";
import RandomisationForm from "./Forms/Randomisation";
import { Navbar, NavbarBrand, NavbarText, Nav, NavLink, Container, Row, Col } from "reactstrap";
import ahi_logo from "./assets/images/ahi_logo.png";
import usyd_logo from "./assets/images/usyd-logo.1280.jpeg";

function Topbar() {
    const { state, dispatch } = React.useContext<IAuthContext>(AuthContext);

    var userElements = <></>;
    if (state.user) {
        userElements = (
            <>
                <Nav>
                    <NavLink
                        href="#"
                        onClick={(event) => {
                            event.preventDefault();
                            window.location.reload();
                        }}
                    >
                        <NavbarText>
                            <FontAwesomeIcon icon={faRandom} /> Randomise
                        </NavbarText>
                    </NavLink>
                    <NavLink
                        href="#"
                        onClick={(event) => {
                            event.preventDefault();
                            AuthLogout(dispatch);
                        }}
                    >
                        <NavbarText>
                            <FontAwesomeIcon icon={faSignOut} /> Logout ({state.user.username})
                        </NavbarText>
                    </NavLink>
                </Nav>
            </>
        );
    }
    return (
        <Navbar className="fixed-top">
            <NavbarBrand>
                <img src={ahi_logo} className="logo_header" alt="AHI logo" />
                <span className="appTitle">RARing: Response Adaptive Randomisation tool</span>
            </NavbarBrand>
            {userElements}
        </Navbar>
    );
}

function Footer() {
    return (
        <Container className="footer">
            <Row className="top_divider">
                <Col md={12}></Col>
            </Row>

            <Row className="footer_body">
                <Col className="footer_about" sm={12} md={6}>
                    <h3>Adaptive Health Intelligence</h3>

                    <p>
                        We are a not-for-profit research team specialising in pragmatic and adaptive clinical trials.
                        Our projects focus on reducing the burden of infectious diseases by improving how routinely used
                        medications are used in clinical practice.
                    </p>
                    <p>In partnership with the University of Sydney (Health and Clinical Analytics)</p>
                    <Row>
                        <Col>
                            <img className="footer_about__logo" src={usyd_logo} alt="University of Sydney logo" />
                        </Col>
                        <Col>
                            <img className="footer_about__logo" src={ahi_logo} alt="AHI logo" />
                        </Col>
                    </Row>
                </Col>
                <Col className="footer_links" sm={12} md={3}>
                    <h3>Quick links</h3>
                    <ul>
                        <li>
                            <a href="https://adaptivehealthintelligence.org.au/about/">&gt; About</a>
                        </li>
                        <li>
                            <a href="https://adaptivehealthintelligence.org.au/current-research-projects">
                                &gt; Projects
                            </a>
                        </li>
                        <li>
                            <a href="https://adaptivehealthintelligence.org.au/resources">&gt; Resources</a>
                        </li>
                        <li>
                            <a href="https://adaptivehealthintelligence.org.au/news-events">&gt; News &amp; Events</a>
                        </li>
                        <li>
                            <a href="https://adaptivehealthintelligence.org.au/contact">&gt; Contact</a>
                        </li>
                        <li>
                            <a href="https://adaptivehealthintelligence.org.au/privacy-policy/">&gt; Privacy Policy</a>
                        </li>
                    </ul>
                </Col>
                <Col className="footer_contact" sm={12} md={3}>
                    <h3>Contact us</h3>
                    <p>We are located at:</p>
                    <p>
                        Telethon Kids Institute
                        <br />
                        Northern Entrance, Perth Children's Hospital
                        <br />
                        15 Hospital Ave, Nedlands 6009
                    </p>
                    <p>
                        E: <a href="mailto:adaptivehealth@telethonkids.org.au">adaptivehealth@telethonkids.org.au</a>
                    </p>
                </Col>
            </Row>
            <Row className="bottom_divider">
                <Col md={12}></Col>
            </Row>
        </Container>
    );
}

function RARingRoutes() {
    return (
        <HashRouter>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/clinicaltrial/:id" component={RandomisationForm} />
        </HashRouter>
    );
}

function MaybeLoginContainer() {
    const { state } = React.useContext(AuthContext);

    // FIXME: make this message more helpful
    if (state.error) {
        return <SimpleError>Authentication error: {state.error}. Please contact user support.</SimpleError>;
    }

    if (!state.valid) {
        return <SimpleError>One moment, RARing is starting.</SimpleError>;
    }

    if (state.user) {
        // we are logged in! delegate to the router
        return <RARingRoutes />;
    }
    return (
        <>
            <LoginForm />
        </>
    );
}

function App() {
    return (
        <>
            <AuthProvider>
                <Topbar />
                <div id="main">
                    <MaybeLoginContainer />
                </div>
            </AuthProvider>
            <Footer />
        </>
    );
}

export default App;
