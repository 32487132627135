import React, { useEffect, useRef } from "react";
import {
    Alert,
    Card,
    CardBody,
    Container,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Col,
    Row,
    UncontrolledAlert,
    Table,
} from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";
import { generateAllocation, APIAllocationResult } from "../API/randomisation";
import { getSchema, APISchemaResult } from "../API/schema";
import { RouteComponentProps } from "react-router-dom";
import SimpleError from "./SimpleError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

function useInput(type: InputType) {
    const [value, setValue] = React.useState("");
    const input = (
        <Input sm={8} name="participant_ref" value={value} type={type} onChange={(e) => setValue(e.target.value)} />
    );
    return {
        value: value,
        input: input,
        setValue: setValue,
    };
}

interface ValidatedProps {
    trialId: string;
}

function RandomisationFormValidated({ trialId }: React.PropsWithChildren<ValidatedProps>) {
    const { value: patientReference, input: patientReferenceInput, setValue: setPatientReference } = useInput("text");
    const [failureMessage, setFailureMessage] = React.useState<string | undefined>();
    const [result, setResult] = React.useState<APIAllocationResult | undefined>();
    const [schema, setSchema] = React.useState<APISchemaResult | undefined>();
    const isMounted = useRef(true);

    const FailureComponent = () => {
        if (failureMessage) {
            return (
                <UncontrolledAlert
                    id="alertStudyIdFormat"
                    aria-label="Study ID Format alert"
                    color="danger"
                    onClick={(ev) => {
                        ev.preventDefault();
                        resetResult();
                    }}
                >
                    {failureMessage}
                </UncontrolledAlert>
            );
        }
        return <></>;
    };

    const submitDisabled = () => patientReference.length === 0;

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        const doRequest = async () => {
            let inputs = {};
            const formInputs = document.getElementsByTagName("input");
            for (let index = 0; index < formInputs.length; ++index) {
                const inputKey = formInputs[index].name;
                const inputValue = formInputs[index].value.toString();
                const inputKeyValue = { [inputKey]: inputValue };
                inputs = { ...inputs, ...inputKeyValue };
            }

            const result = await generateAllocation(trialId, patientReference, inputs);

            if (!result.success) {
                setFailureMessage(result.error_message);
                return;
            }
            setResult(result.result);
        };

        e.preventDefault();
        if (validateStudyId(patientReference)) {
            doRequest();
        }
    }

    function validateStudyId(studyId: string) {
        const studyIdRegex = /^[0-9]{4,5}-[0-9]{1,2}$/;
        if (!studyIdRegex.test(studyId)) {
            setFailureMessage(
                "Invalid study ID (" +
                    studyId +
                    "). Study ID must be in the format XXXX[X]-X[X] where X is a number, with those in square brackets optional."
            );
            return false;
        }
        setFailureMessage(undefined);
        return true;
    }

    // build the form fields for the model inputs dynamically
    const SchemaComponent = () => {
        if (schema) {
            const schemaElements = Object.entries(schema).map(([data, key]) => {
                return (
                    <FormGroup row key={key.name}>
                        <Col sm={4}>
                            <Label for={data}>{key.name}</Label>
                        </Col>
                        <Col name={data} sm={8}>
                            <input name={data} type="text" className="form-control" />
                        </Col>
                    </FormGroup>
                );
            });
            return (
                <Row>
                    <Col>{schemaElements}</Col>
                </Row>
            );
        }
        return <></>;
    };

    useEffect(() => {
        // get schema from Random API to build randomisation form
        const schemaFields = async () => {
            const schema = await getSchema(trialId);
            if (!schema.success && isMounted.current) {
                setFailureMessage(schema.error_message);
                return;
            }
            if (isMounted.current) {
                setSchema(schema.result);
            }
        };
        schemaFields();
        return () => {
            isMounted.current = false;
        };
    }, [trialId]);

    const resetResult = () => {
        setResult(undefined);
        setFailureMessage(undefined);
        setPatientReference("");
    };

    const ResultCard = () => {
        if (!result) {
            return <></>;
        }

        return (
            <Card className="AllocationCard">
                <CardBody>
                    <Row>
                        <Col>
                            <h4>Random allocation successfully assigned and recorded in REDCap</h4>
                        </Col>
                    </Row>
                    <Table dark responsive className="allocationTable">
                        <tbody>
                            <tr>
                                <th>Study ID</th>
                                <td>{patientReference}</td>
                            </tr>
                            <tr>
                                <th>Arm ID</th>
                                <td>{result.allocation.arm_id}</td>
                            </tr>
                            <tr>
                                <th>Description</th>
                                <td>{result.allocation.arm}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        );
    };

    if (!result) {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            <h1>Randomise</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert id="warningBaseline" color="warning">
                                <FontAwesomeIcon icon={faTriangleExclamation} />
                                &nbsp; Please do not randomise until the baseline EP Physical Assessments are completed.
                            </Alert>
                        </Col>
                    </Row>
                    <FailureComponent />
                    <Card className="randomiseForm">
                        <CardBody>
                            <Form onSubmit={handleSubmit}>
                                <FormGroup row className="g-0">
                                    <Label for="participant_ref">Study ID</Label>
                                    {patientReferenceInput}
                                    <Alert id="helpStudyIdFormat" color="primary">
                                        <FontAwesomeIcon icon={faCircleInfo} />
                                        &nbsp; Enter the Study-ID exactly as it appears in REDCap. E.g. 1234-1,
                                        12345-11, etc.
                                    </Alert>
                                </FormGroup>

                                <SchemaComponent />

                                <Button disabled={submitDisabled()}>Randomise</Button>
                            </Form>
                        </CardBody>
                    </Card>
                    <ResultCard />
                </Container>
            </div>
        );
    } else {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            <h1>Randomisation result</h1>
                        </Col>
                    </Row>
                    <FailureComponent />
                    <ResultCard />
                </Container>
            </div>
        );
    }
}

// https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
const uuid4_re = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

type RandomisationFormRouteParams = {
    id: string;
};

export default function RandomisationForm({ match }: RouteComponentProps<RandomisationFormRouteParams>) {
    // validate UUID4 parameter is actually a UUID4
    const trialId = match.params.id;
    if (!trialId.match(uuid4_re)) {
        return <SimpleError>Clinical trial ID is invalid.</SimpleError>;
    }

    return <RandomisationFormValidated trialId={trialId} />;
}
