import { Container, Col, Row } from "reactstrap";

export default function RandomisationForm() {
    return (
        <Container>
            <Row>
                <Col>Welcome to the RARing response adaptive randomisation system.</Col>
            </Row>
        </Container>
    );
}
